import ClassCard from "app/components/ClassCard";
import { Slider } from "app/components/Slider";
import React from "react";
import { Routes } from "constants/routes";
import { HomeSelectContentContainer } from "services/typewriter/segment";
import { Section } from "../Section";
import { useSearchClassesForDashboard } from "../hooks";

export const Workouts = () => {
  const { classResults: workouts, loading } = useSearchClassesForDashboard({
    defaultFilters: ["type:'Workouts'"],
  });

  if (workouts?.length === 0) {
    return null;
  }

  return (
    <Section
      title="Workouts"
      showLoadingState={loading}
      headerLink={{
        route: `${Routes.results__classes}?refinementList[type][0]=Workouts`,
        text: "See All",
      }}
    >
      <Slider
        data={workouts}
        slideComponent={(props: any) => (
          <ClassCard
            {...props}
            container={HomeSelectContentContainer.Workouts}
            selectedFrom="Workouts"
          />
        )}
        slidesSpacing={24}
        cardHeight="190px"
      />
    </Section>
  );
};
