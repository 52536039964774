import moment from "moment";
import {
  Class,
  useGetHomeDataLazyQuery,
  useCreateRecommendedSchedulePlaylistV2Mutation,
  useSearchClassesForDashboardLazyQuery,
} from "services/graphql";
import { useEffect } from "react";
import { useAccessTypeFilterForUser } from "app/components/Algolia/hooks";

export function useGetHomeData() {
  const date = moment().format("YYYY-MM-DD");

  const [getHomeData, { data, loading, error }] = useGetHomeDataLazyQuery({
    variables: { date },
    /**
     * Ideally don't want to do this, but without it
     * going from home --> history --> home will result in https://stzy.atlassian.net/browse/CORE-2057.
     *
     * For whatever reason, even without fetchPolicy = "network-only", we re-fetch anyways,
     * so just doing this explicitly to not use cache & get it working,
     * but the root cause is still there. May have something to do with how we handle
     * things in `/config/gql-cache.js`.
     * Also, the query itself might be not querying for something needed that is a key (e.g. id).
     */
    fetchPolicy: "network-only",
  });

  const [
    createRecommendedMutation,
  ] = useCreateRecommendedSchedulePlaylistV2Mutation();

  useEffect(() => {
    /**
     * Need to try to create first b/c grabbing the home data, in particular playlist data,
     * will blow up if it doesn't exist. Luckily the create mutation is idempotent.
     */

    createRecommendedMutation({ variables: { date } }).then(() => {
      getHomeData();
    });
  }, []);

  const todaysClasses = data?.todaysPlaylist?.classes || [];

  let continueDancing: Class[] = [];
  data?.continueDancing?.classes?.forEach((item: any) =>
    continueDancing.push(item?.class)
  );

  continueDancing = continueDancing.filter((node: any) => {
    return node.__typename === "Class" || node.__typename === "StudioClass";
  });

  const dancePrograms = data?.dancePrograms?.programs || [];

  const savedClasses: Class[] = [];
  data?.savedClasses?.classes?.forEach((item: any) =>
    savedClasses.push(item?.class)
  );

  return {
    todaysClasses,
    continueDancing,
    dancePrograms,
    savedClasses,
    loading: loading || !data,
    error,
  };
}

export function useSearchClassesForDashboard({
  defaultFilters = [],
}: {
  defaultFilters: string[];
}) {
  const accessTypeFilterForUser = useAccessTypeFilterForUser();
  const [
    execSearchClassesForDashboard,
    { data, loading, error },
  ] = useSearchClassesForDashboardLazyQuery();

  useEffect(() => {
    if (!accessTypeFilterForUser) {
      return;
    }

    const accessTypeFilters = accessTypeFilterForUser.map(
      accessType => `accessType:${accessType}`
    );
    const accessTypeFiltersString = accessTypeFilters.join(" OR ");

    const filtersArray =
      accessTypeFilters.length > 0
        ? [...defaultFilters, accessTypeFiltersString]
        : defaultFilters;
    const filters = filtersArray.join(" AND ");

    execSearchClassesForDashboard({
      variables: {
        filters,
      },
    });
  }, [accessTypeFilterForUser]);

  const classResults: Class[] = data?.classesForDashboard?.classes?.map(
    (item: any) => item?.class
  );

  return {
    classResults,
    loading: loading || !data,
    error,
  };
}
